<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search here..."
            />
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              text="Export"
              variant="outline-primary"
              right
              class="mr-1"
              @click="$emit('export-table')"
            >
              <b-dropdown-item @click="$emit('export-table')">
                <img
                  src="https://img.icons8.com/color/48/000000/ms-excel.png"
                  width="25px"
                  height="auto"
                > Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      :fields="masterSetup.fieldColumns"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #cell(qty)="data">
        {{ convertNumber(data.item.qty) }}
      </template>
      <template #cell(total)="data">
        IDR {{ convertNumber(data.item.total) }}
      </template>
      <!-- Column: Actions -->
      <template #cell(status)="data">
        <b-badge
          v-if="data.item.status === 'VERIFIED'"
          pill
          class="d-block"
          variant="light-success"
        >
          PAID
        </b-badge>
        <b-badge
          v-else-if="data.item.status === 'OPEN'"
          pill
          class="d-block"
          variant="light-danger"
        >
          UNPAID
        </b-badge>
        <b-badge
          v-else-if="data.item.status === 'UNVERIFIED'"
          pill
          class="d-block"
          variant="light-warning"
        >
          UNVERIFIED
        </b-badge>
        <b-badge
          v-else-if="data.item.status === 'PARTIAL_PAID'"
          pill
          class="d-block"
          variant="light-danger"
        >
          PARTIAL PAID
        </b-badge>
        <b-badge
          v-else-if="data.item.status === 'EXPIRED'"
          pill
          class="d-block"
          variant="light-danger"
        >
          EXPIRED
        </b-badge>
      </template>

      <template #cell(id)="data">

        <div class="text-nowrap">
          <b-button
            :id="`invoice-row-${data.item.id}-detail-icon`"
            v-ripple.400="'rgba(30, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="$emit('detail-modal',data.item)"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
          <b-tooltip
            title="Detail Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-detail-icon`"
          />
          <b-button
            v-if="data.item.status === 'OPEN'"
            :id="`invoice-row-${data.item.id}-edit-icon`"
            v-ripple.400="'rgba(30, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="$emit('edit-modal',data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-tooltip
            title="Verify Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert, VBModal,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import useInvoicesList from './useTableList'

import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BAlert,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ['masterSetup', 'refreshData', 'convertNumber'],
  data() {
    return {
      log: [],
      pageLength: 3,
      dir: false,
      pages: ['3', '5', '10'],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active', 2: 'Inactive',
      }],
    }
  },
  watch: {
    refreshData() {
      this.refetchData()
    },
  },
  methods: {
    handleSearch(searching) {
      this.log.push(`The user searched for: ${searching}`)
    },
    handleChangePage(page) {
      this.log.push(`The user changed the page to: ${page}`)
    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`)
    },
    onSortChange(params) {
      this.log.push(`the user ordered:  ${params[0].type}`)
    },
  },
  setup(props) {
    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList(props.masterSetup)

    return {
      fetchInvoices,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
