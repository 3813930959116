<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <!-- 3 Card of Total Product + Total Active Product + Add New -->
      <b-row v-if="masterSetup.title">
        <b-col
          md="6"
          lg="4"
        >
          <!-- Card Total Product -->
          <b-card>
            <b-card-text>
              Total {{ masterSetup.title }}
            </b-card-text>
            <h3 class="mb-2">
              {{ convertNumber(masterSetup.total) +' '+ masterSetup.cardTitle }}
            </h3>

            <div class="d-flex justify-content-between flex-wrap">
              <b-card-text>
                Information.
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(30, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="BoxIcon" />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
        >

          <!-- Card Active Product -->
          <b-card>
            <b-card-text>
              Active {{ masterSetup.title }}
            </b-card-text>
            <h3 class="mb-2">
              {{ convertNumber(masterSetup.totalActive) +' '+ masterSetup.cardTitle }}
            </h3>
            <div class="d-flex justify-content-between flex-wrap">
              <b-card-text>
                Information.
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(30, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="BoxIcon" />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
        >

          <!-- Card Add New Product -->
          <b-card v-if="isShow">
            <b-row>
              <b-col cols="5">
                <b-img
                  :src="require('@/assets/images/products/icon-product.png')"
                  height="120"
                />
              </b-col>
              <b-col cols="7">
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="justify-content-end mb-2 mt-2"
                    @click="openAddModal()"
                  >
                    {{ `Add New ${masterSetup.title}` }}
                  </b-button>
                  <br>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <b-card-text class="text-right align-content-right">
                    Add {{ masterSetup.title }} If It Doesn't Exist.
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- End of 3 Card of Total Product + Total Active Product + Add New -->

      <!-- Table Card -->
      <b-card>
        <b-row>
          <b-col cols="12">
            <table-list
              v-if="masterSetup.getDataEndpoint"
              :master-setup="masterSetup"
              :refresh-data="refreshData"
              :convert-number="convertNumber"
              @export-table="exportTable"
              @detail-modal="openDetailModal"
              @edit-modal="openEditModal"
              @delete-confirm="deleteConfirm"
            />
          </b-col>
        </b-row>
      </b-card>

      <!-- Modal for Show Product -->
      <b-modal
        id="modal-login"
        cancel-variant="outline-secondary"
        ok-only
        ok-title="Close"
        centered
        title="Product #ID"
      >
        <b-form>
          <b-form-group
            label="Brand Code"
            label-for="plainTextInput"
          >
            <b-form-input
              id="brand-code"
              plaintext
              value="5036"
            />
          </b-form-group>
          <b-form-group
            label="Brand Name"
            label-for="plainTextInput"
          >
            <b-form-input
              id="brand-name"
              plaintext
              value="Product A"
            />
          </b-form-group>
          <b-form-group
            label="Category"
            label-for="plainTextInput"
          >
            <b-form-input
              id="category"
              plaintext
              value="Retail"
            />
          </b-form-group>
          <div>
            <b-card-text class="mb-0">
              Status
            </b-card-text>
          </div>
        </b-form>
      </b-modal>

      <!-- Modal for New Product -->
      <b-modal
        v-if="masterSetup.fields"
        ref="masterModal"
        scrollable
        :title="modalInfo.title"
        cancel-title="Close"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @ok="submitModal"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-card-text>
            Please Upload your transfer receipt. File format should be jpg or png and not more than 2mb
          </b-card-text>
          <validation-observer
            ref="importRules"
            tag="form"
          >
            <validation-provider
              #default="{ errors }"
              :name="masterSetup.fields[0].label"
              :rules="masterSetup.fields[0].rules"
            >
              <b-form-file
                ref="importFile"
                v-model="importFile.file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".jpg, .jpeg, .png"
                :state="errors.length > 0 ? false:null"
                @change="handleFileUpload( $event )"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </b-overlay>
      </b-modal>

      <!-- Modal for Detail Product -->
      <b-modal
        ref="detailModal"
        scrollable
        title="Detail Invoice"
        ok-only
        size="lg"
        ok-title="Close"
        cancel-variant="outline-secondary"
      >
        <div>
          <b-row class="mb-1">
            <b-col cols="6">
              Status
            </b-col>
            <b-col cols="6">
              : {{ productModel.status }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Payment Type
            </b-col>
            <b-col cols="6">
              : {{ productModel.payment_type }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Bukti Pembayaran
            </b-col>
            <b-col
              v-if="productModel.image_url"
              cols="6"
            >
              : <b-link
                :href="productModel.image_url"
                target="_blank"
                class="alert-link text-info"
              >
                <span>Image</span>
              </b-link>
            </b-col>
            <b-col
              v-else
              cols="6"
            >
              : N/A
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Total Module
            </b-col>
            <b-col cols="6">
              : {{ productModel.total_qty }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Sub Total
            </b-col>
            <b-col cols="6">
              : {{ convertNumber(Math.round(productModel.amount)) }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Tax Rate
            </b-col>
            <b-col cols="6">
              : {{ productModel.tax_rate }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Tax Amount
            </b-col>
            <b-col cols="6">
              : {{ convertNumber(Math.round(productModel.amount * (productModel.tax_rate/100))) }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Total
            </b-col>
            <b-col cols="6">
              : {{ convertNumber(Math.round(productModel.amount + (productModel.amount * (productModel.tax_rate/100)))) }}
            </b-col>
          </b-row>
          <h5>Detail Module</h5>
          <b-table
            :items="productModel.detail_order"
            :fields="detailFields"
          >
            <template #cell(qty)="data">
              {{ data.item.module_name === 'Periode' ? `${convertNumber(data.item.qty)} Months` : convertNumber(data.item.qty) }}
            </template>
            <template #cell(amount)="data">
              {{ data.item.module_name === 'Periode' ? '-' : convertNumber(data.item.amount) }}
            </template>
            <template #cell(total)="data">
              {{ data.item.module_name === 'Periode' ? '-' : convertNumber(data.item.qty * data.item.amount) }}
            </template>
          </b-table>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BAvatar,
  BBadge,
  BTable,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BImg,
  BModal,
  BForm,
  VBModal,
  BFormCheckbox,
  BFormFile,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { makeToast, numberWithCommas } from '@/@fake-db/utils'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  between, integer, required, min, max, ext, size,
} from '@core/utils/validations/validations'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle,import/extensions
import TableList from './table/Table'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
    BAvatar,
    BBadge,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BModal,
    BForm,
    vSelect,
    BFormCheckbox,
    BFormFile,
    BFormDatepicker,
    BOverlay,
    TableList,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      selectedStore: '',
      showLoading: false,
      isShow: false,
      // Product Data
      products: [],
      optionField: [],
      productModel: {},
      refreshData: 0,
      importFile: {},
      modalInfo: {
        title: '',
        type: '',
      },
      file: '',
      detailFields: [{ key: 'module_name', label: 'Module Name' },
        { key: 'qty', label: 'QTY' },
        // { key: 'expired', label: 'expired', sortable: true },
        { key: 'amount', label: 'Amount' },
        { key: 'total', label: 'Sub Total Amount' }],
      required,
      between,
      min,
      max,
      integer,
      ext,
      size,
    }
  },

  setup() {
    const { router } = useRouter()
    const masterSetup = ref({})
    const fieldOptions = ref({})
    const masterType = 'billing'
    useJwt.jwtGet(`/master/setup-list/${masterType}`)
      .then(res => {
        // eslint-disable-next-line prefer-destructuring
        masterSetup.value = res.data[1]
      }).catch(err => {
        if (err.response.status === 403) {
          useJwt.logout()
          router.replace({ name: 'auth-login' })
        } else {
          router.replace({ name: 'error-404' })
        }
      })
    return {
      masterSetup,
      fieldOptions,
    }
  },
  computed: {},
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0]
    },
    exportTable() {
      this.showLoading = true
      useJwt.jwtGet(this.masterSetup.exportEndpoint).then(() => {
        makeToast(this, 'Export Success', 'EditIcon', 'success')
        this.showLoading = false
      }).catch(e => {
        this.showLoading = false
        if (e.response.status === 403) {
          useJwt.logout()
          this.$router.replace({ name: 'auth-login' })
        }
        makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
      })
    },
    downloadTemplate() {
      this.showLoading = true
      useJwt.jwtGet(this.masterSetup.templateEndpoint).then(() => {
        this.showLoading = false
        makeToast(this, 'Download Template Success', 'EditIcon', 'success')
      }).catch(e => {
        this.showLoading = false
        if (e.response.status === 403) {
          useJwt.logout()
          this.$router.replace({ name: 'auth-login' })
        }
        makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
      })
    },
    submitModal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.validationForm().then(() => {
        this.showLoading = true
        const data = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        data.append('id', this.importFile.id)
        data.append(this.masterSetup.fields[0].name, this.file)
        useJwt.jwtPostFile(this.modalInfo.submitEndpoint, data).then(() => {
          this.showLoading = false
          makeToast(this, 'Form Submitted', 'EditIcon', 'success')
          this.$refs.masterModal.hide()
          this.importFile = {}
          // eslint-disable-next-line no-plusplus
          this.refreshData++
        }).catch(e => {
          this.showLoading = false
          if (e.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
          makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
        })
      })
    },
    openAddModal() {
      this.$swal({
        title: 'Are you sure ?',
        text: 'You still have active billing, if you create new one it will replace the old',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'homepage' })
          }
        })
    },
    openDetailModal(data) {
      store.dispatch('app-billing/fetchMasterTable', { id: data.id, masterType: this.masterSetup.name, endpoint: this.masterSetup.getDetailEndpoint })
        .then(response => {
          this.productModel = response.data
          this.$refs.detailModal.show()
        }).catch(err => {
          this.showLoading = false
          if (err.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
        })
    },
    openEditModal(data) {
      this.importFile.id = data.id
      this.showLoading = true
      this.showLoading = false
      this.modalInfo.title = `Confirm ${this.masterSetup.title}`
      this.modalInfo.type = 'edit'
      this.modalInfo.submitEndpoint = this.masterSetup.editEndpoint
      this.$refs.masterModal.show()
    },
    filterOption(data, field) {
      if (field.parent_id > 0) {
        const parent = this.masterSetup.fields.find(e => e.id === field.parent_id)
        const filtered = data.filter(e => this.productModel[parent.name] && e.parent_id === this.productModel[parent.name])
        if (filtered.length === 0) this.productModel[field.name] = ''
        return filtered
      }
      return data
    },
    resetChild(data, field) {
      if (field.parent_id > 0) return this.filterOption(data, field)
      const child = this.masterSetup.fields.find(e => e.parent_id === field.id).name
      if (this.productModel[field.name]) this.productModel[child] = ''
      return true
    },
    // confirm texrt
    deleteConfirm(data) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.showLoading = true
            useJwt.jwtGet(this.masterSetup.deleteEndpoint, { id: data.id }).then(() => {
              this.showLoading = false
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              // eslint-disable-next-line no-plusplus
              this.refreshData++
            })
              .catch(e => {
                this.showLoading = false
                if (e.response.status === 403) {
                  useJwt.logout()
                  this.$router.replace({ name: 'auth-login' })
                }
                makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
              })
          }
        })
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.importRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    },
    validationImportForm() {
      return new Promise((resolve, reject) => {
        this.$refs.importRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
